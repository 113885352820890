import { useTranslation } from "react-i18next";
import { Integration, Mapping } from "/app/src/models";
import { Row, Col } from "antd";
import { buildParams } from "/app/src/helpers/params";
import { mappingService, reportColumnTypeService } from "/app/src/services";
import { useQuery } from "@tanstack/react-query";
import JoinCondition from "./joinCondition";
import NewJoinCondition from "./newJoinCondition";
import useSettings from "/app/src/components/settings/setting/useSettings";
import JoinType from "./joinType";
import NextDivider from "/app/src/components/NextUi/Divider";

/**
 *
 * @param param0 integration
 * @returns PrimaryMapping component
 */
export default function PrimaryMapping({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();

  const {
    settings: joinConditions,
    createSetting,
    updateSetting,
    deleteSetting,
  } = useSettings({ type: "joinCondition", integrationId: integration.id });
  const { data: reportColumnTypes } = useQuery({
    queryKey: ["columnTypes", integration.baseTable, "notVariance"],
    queryFn: () => {
      return reportColumnTypeService.getAll(
        buildParams({
          baseTable: integration.baseTable,
          orderby: "name",
          table: "[not]Variance",
        }),
      );
    },
    placeholderData: { report_column_types: [] },
    enabled: Boolean(integration.baseTable),
    select: (data) => data.report_column_types,
  });

  const { data: mappings } = useQuery({
    queryKey: ["mappings", integration.id],
    queryFn: () => {
      return mappingService.getAll(
        buildParams({
          integrationId: integration.id,
          orderby: "key",
          parentMappingId: "none",
        }),
      );
    },
    enabled: Boolean(integration.id),
    initialData: { mappings: [] },
    select: (data: { mappings: Mapping[] }) => {
      return data.mappings;
    },
  });

  return (
    <>
      <Row className="pb-3">
        <Col className="text-lg font-semibold" span={8}>
          {t("translation:data_mappings")}
        </Col>
      </Row>
      <Row justify="start" gutter={16}>
        <Col span={8}>
          <h3>{t("translation:primary_mapping")}</h3>
          <p>{t("translation:primary_mapping_description")}</p>
          <NextDivider className="mb-3" />
          <JoinType integration={integration} />
        </Col>
        <Col span={16}>
          <>
            {joinConditions.map((joinCondition) => (
              <JoinCondition
                joinCondition={joinCondition}
                updateJoinCondition={updateSetting}
                deleteJoinCondition={deleteSetting}
                integration={integration}
                reportColumnTypes={reportColumnTypes}
                mappings={mappings}
                key={joinCondition.id}
              />
            ))}
          </>
        </Col>
      </Row>
      <NewJoinCondition
        integration={integration}
        createJoinCondition={createSetting}
        mappings={mappings}
        reportColumnTypes={reportColumnTypes}
      />
    </>
  );
}
