import React, { useCallback, useState } from "react";
import { Col, Row, Alert } from "antd";
import { Form, SubmitButton, Input, Select } from "formik-antd";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import Attachments from "./attachments";
import { emailSchema } from "/app/src/schemas";
import { WorkflowAction, Report, App } from "/app/src/models";
import { workflowActionService } from "/app/src/services";
import { convertArrayToCSV, convertCSVtoArray } from "/app/src/helpers";
import { handlePromiseError } from "/app/src/helpers/api";
import NextButton from "/app/src/components/NextUi/Button";

/**
 * Find an email attachment in a list of attachments.
 * @param: {WorkflowAction} attachment - The attachment to find.
 * @param: {WorkflowAction[]} attachments - The list of attachments to search.
 * @return {WorkflowAction|null} Returns the promise containing data of API response.
 */
function findAttachment(
  attachment: WorkflowAction,
  attachments: WorkflowAction[],
) {
  const duplicate = attachments.find(
    (a) =>
      a.appId === attachment.appId &&
      a.integrationId === attachment.integrationId &&
      a.reportId === attachment.reportId,
  );
  if (duplicate) {
    return duplicate;
  }
  return null;
}

export default function Email({
  app,
  action,
  addWorkflowAction,
  removeWorkflowAction,
  updateAction,
  isNew,
  resetSelectedApp,
  reports,
}: {
  app: App;
  action: WorkflowAction;
  addWorkflowAction: (action: WorkflowAction) => Promise<any>;
  removeWorkflowAction: (action: WorkflowAction) => Promise<any> | undefined;
  updateAction: (action: WorkflowAction) => Promise<any>;
  isNew: boolean;
  resetSelectedApp: () => void;
  reports: Report[];
}) {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const [attachments, setAttachments] = useState<WorkflowAction[]>(
    action?.children || [],
  );
  const handleErrorClose = useCallback(() => {
    setError(null);
  }, []);
  const handleWorkflowDelete = useCallback(() => {
    removeWorkflowAction(action);
  }, [action, removeWorkflowAction]);
  const handleSubmission = useCallback(
    (values, actions) => {
      //convert email address list to csv
      values.to = convertArrayToCSV(values.to);
      if (isNew) {
        const newAction = {
          ...values,
          appId: app.id,
          children: attachments,
        } as WorkflowAction;
        addWorkflowAction(newAction).then((response) => {
          if (!response?.errors) {
            actions.resetForm();
            resetSelectedApp();
          }
        });
      } else {
        if (action.id) {
          updateAction({ id: action.id, ...values })
            .then((response) => {
              if (!response?.errors) {
                actions.resetForm();
              }
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }
      }
    },
    [
      isNew,
      action,
      attachments,
      addWorkflowAction,
      updateAction,
      app,
      resetSelectedApp,
    ],
  );
  const addAttachment = useCallback(
    (attachment: WorkflowAction) => {
      setError(null);
      //check to see if duplicate attachment
      const duplicate = findAttachment(attachment, attachments);
      if (duplicate) {
        setError(t("translation:workflow_duplicateAttachment"));
        return;
      }
      setAttachments([...attachments, attachment]);
      //if the parent Action is already saved, create new attachment in the database
      if (!isNew) {
        workflowActionService.createSingle({
          appId: attachment.appId,
          integrationId: attachment.integrationId,
          reportId: attachment.reportId,
          parentActionId: action.id,
          workflowId: attachment.workflowId,
        });
      }
    },
    [attachments, action, isNew, t],
  );
  const removeAttachment = useCallback(
    (attachment: WorkflowAction) => {
      //find the attachment to delete
      const attachmentToDelete = findAttachment(attachment, attachments);
      //If the attachment is already saved, delete it from the database
      if (attachmentToDelete?.id) {
        workflowActionService.deleteSingle(attachmentToDelete.id);
      }
      //remove attachment from state
      const index = attachments.indexOf(attachmentToDelete);
      const arrayCopy = [...attachments];
      arrayCopy.splice(index, 1);
      setAttachments(arrayCopy);
    },
    [attachments],
  );
  const updateAttachment = useCallback(
    (oldAction: WorkflowAction, workflowAction: WorkflowAction) => {
      const newArray = [...attachments];
      //check if new object is a duplicate
      const duplicate = findAttachment(workflowAction, newArray);
      if (duplicate) {
        setError(t("translation:workflow_duplicateAttachment"));
        return;
      }
      //replace old object with updated object
      const index = newArray.indexOf(oldAction);

      if (!isNew) {
        //if the parent Action is already saved, update the attachment in the database
        workflowActionService
          .updateSingle(oldAction.id, {
            appId: workflowAction.appId,
            integrationId: workflowAction.integrationId,
            reportId: workflowAction.reportId,
          })
          .then(handlePromiseError)
          .then((response) => {
            newArray[index] = response.workflow_action;
            setAttachments(newArray);
          });
      }
    },
    [attachments, t, isNew],
  );
  return (
    <>
      <Row justify="start" gutter={16}>
        <Col span={24}>
          <Formik
            validationSchema={emailSchema}
            enableReinitialize
            initialValues={{
              ...(isNew
                ? { to: undefined }
                : { to: convertCSVtoArray(action.to as string) }),
              ...(isNew ? { value: "" } : { value: action.value }),
              ...(isNew ? { message: "" } : { message: action.message }),
            }}
            onSubmit={handleSubmission}
          >
            {({ dirty }) => (
              <Form>
                <Row justify="start" gutter={16}>
                  <Col span={3}>
                    <span className="blockText">{t("translation:send")}</span>
                  </Col>
                  <Col span={15}>
                    <Form.Item name="to">
                      <Select
                        mode="tags"
                        options={[]}
                        size="large"
                        name="to"
                        placeholder={`${t("translation:enter")} ${t("translation:email_addresses_separated")}`}
                      />
                    </Form.Item>
                    <Form.Item name="value">
                      <Input
                        suffix
                        size="large"
                        name="value"
                        placeholder={`${t("translation:enter")} ${t(
                          "translation:email_subject",
                        )}`}
                      />
                    </Form.Item>
                    <Form.Item name="message">
                      <Input.TextArea
                        name="message"
                        placeholder={`${t("translation:enter")} ${t(
                          "translation:email_message",
                        )}`}
                        autoSize={{ minRows: 3, maxRows: 10 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col flex="auto">
                    <SubmitButton
                      type="primary"
                      size="large"
                      block
                      disabled={!dirty}
                    >
                      {isNew
                        ? t("translation:add_action")
                        : t("translation:save")}
                    </SubmitButton>
                  </Col>
                  {!isNew && (
                    <Col span={3}>
                      <NextButton
                        size="md"
                        variant="bordered"
                        color="default"
                        fullWidth
                        onClick={handleWorkflowDelete}
                        className="hover:border-blue-500 hover:text-blue-500 bg-white"
                      >
                        {t("translation:remove")}
                      </NextButton>
                    </Col>
                  )}
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      <Row justify="start" gutter={16}>
        <Col span={24}>
          <Attachments
            parentAction={action}
            reports={reports}
            attachments={attachments}
            addAttachment={addAttachment}
            removeAttachment={removeAttachment}
            updateAttachment={updateAttachment}
          />
          {error && (
            <Alert
              message={error}
              type="error"
              onClose={handleErrorClose}
              closable
            />
          )}
        </Col>
      </Row>
    </>
  );
}
