import React, { useCallback } from "react";
import { workflowService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import NextButton from "/app/src/components/NextUi/Button";

export default function TestWorkflow({
  workflowId,
}: {
  workflowId: number | undefined;
}) {
  const { t } = useTranslation();

  const testWorkflowHandler = useCallback(() => {
    if (!workflowId) return;
    workflowService.testSingle(workflowId);
  }, [workflowId]);

  return (
    <div className="testWorkflow">
      <NextButton
        fullWidth
        color="default"
        variant="bordered"
        size="sm"
        onClick={testWorkflowHandler}
        className="hover:border-blue-500 hover:text-blue-500 bg-white"
      >
        {t("translation:test_")}
        {t("translation:workflow")}
      </NextButton>
    </div>
  );
}
