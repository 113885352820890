import { Col, Row } from "antd";
import { Form, SubmitButton, Select, Input } from "formik-antd";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { settingService } from "/app/src/services";
import { WorkflowAction, Setting, App } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useQuery } from "@tanstack/react-query";
import NextButton from "/app/src/components/NextUi/Button";
import { useCallback } from "react";

export default function Slack({
  app,
  action,
  addWorkflowAction,
  removeWorkflowAction,
  updateAction,
  isNew,
  resetSelectedApp,
}: {
  app: App;
  action: WorkflowAction;
  addWorkflowAction: (action: WorkflowAction) => Promise<any>;
  removeWorkflowAction: (action: WorkflowAction) => Promise<any> | undefined;
  updateAction: (action: WorkflowAction) => Promise<any>;
  isNew: boolean;
  resetSelectedApp: () => void;
}) {
  const { data: settings } = useQuery({
    queryKey: ["settingsByApp", app.id],
    queryFn: () => {
      return settingService.getAll(buildParams({ appId: app?.id }));
    },
    enabled: Boolean(app?.id),
    initialData: { settings: [] },
    select: (data: { settings: [] }) => {
      return data.settings;
    },
  });

  const removeWorkflowHandler = useCallback(() => {
    removeWorkflowAction(action);
  }, [action, removeWorkflowAction]);

  const addUpdateWorkflowHandler = useCallback(
    (values, actions) => {
      if (isNew) {
        const newAction = { ...values, appId: app.id } as WorkflowAction;
        addWorkflowAction(newAction).then((response) => {
          if (!response?.errors) {
            actions.resetForm();
            resetSelectedApp();
          }
        });
      } else {
        if (action?.id) {
          updateAction({ id: action.id, ...values }).then((response) => {
            if (!response?.errors) {
              actions.resetForm();
            }
          });
        }
      }
    },
    [isNew, app, action, addWorkflowAction, updateAction, resetSelectedApp],
  );

  const { t } = useTranslation();
  return (
    <Row justify="start" gutter={16}>
      <Col span={24}>
        <Formik
          enableReinitialize
          initialValues={{
            ...(isNew ? { to: "" } : { to: action.to }),
            ...(isNew ? { message: "" } : { message: action.message }),
          }}
          onSubmit={addUpdateWorkflowHandler}
        >
          {({ dirty }) => (
            <Form>
              <Row justify="start" gutter={16}>
                <Col span={3}>
                  <span className="blockText">{t("translation:send")}</span>
                </Col>
                <Col span={15}>
                  <Form.Item name="to">
                    <Select
                      name="to"
                      size="large"
                      placeholder={t("translation:select_channel")}
                    >
                      {settings.map((c: Setting) => (
                        <Select.Option key={c.id} value={c.name}>
                          {c.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="message">
                    <Input.TextArea
                      name="message"
                      placeholder={`${t("translation:enter")} ${t(
                        "translation:your_slack_message",
                      )}`}
                      autoSize={{ minRows: 3, maxRows: 10 }}
                    />
                  </Form.Item>
                </Col>
                <Col flex="auto">
                  <SubmitButton
                    type="primary"
                    size="large"
                    block
                    disabled={!dirty}
                  >
                    {isNew
                      ? t("translation:add_action")
                      : t("translation:save")}
                  </SubmitButton>
                </Col>
                {!isNew && (
                  <Col span={3}>
                    <NextButton
                      size="md"
                      variant="bordered"
                      color="default"
                      fullWidth
                      onClick={removeWorkflowHandler}
                      className="hover:border-blue-500 hover:text-blue-500 bg-white"
                    >
                      {t("translation:remove")}
                    </NextButton>
                  </Col>
                )}
              </Row>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
}
