import { useCallback, useMemo } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import NextDivider from "/app/src/components/NextUi/Divider";
import { Form, Select, SubmitButton, InputNumber } from "formik-antd";
import { useTranslation } from "react-i18next";
import { WorkflowCriteria, Report } from "/app/src/models";
import { simpleSchemaBuilder } from "/app/src/helpers";
import NextButton from "/app/src/components/NextUi/Button";

interface FormValues {
  reportId: number | undefined;
  value: string | undefined;
  verb: string | undefined;
}

export default function Criteria({
  criteria,
  removeWorkflowCriteria,
  updateWorkflowCriteria,
  reports,
}: {
  criteria: WorkflowCriteria;
  removeWorkflowCriteria: (
    criteria: WorkflowCriteria,
  ) => Promise<any> | undefined;
  updateWorkflowCriteria: (criteria: WorkflowCriteria) => Promise<any>;
  reports: Report[];
}) {
  const { t } = useTranslation();
  const verbs = useMemo(
    () => [
      { name: "equals", value: "eq" },
      { name: "not", value: "not" },
      { name: "greater than", value: "gt" },
      { name: "less than", value: "lt" },
      { name: "greater or equal", value: "ge" },
      { name: "less or equal", value: "le" },
    ],
    [],
  );

  const updateWorkflowCriteriaHandler = useCallback(
    (values, actions) => {
      if (criteria?.id) {
        updateWorkflowCriteria({ id: criteria.id, ...values }).then(
          (response) => {
            if (!response.errors) {
              actions.resetForm();
            }
          },
        );
      }
    },
    [criteria.id, updateWorkflowCriteria],
  );

  const deleteWorkflowCriteriaHandler = useCallback(() => {
    removeWorkflowCriteria(criteria);
  }, [removeWorkflowCriteria, criteria]);

  const critieriaForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty }) => (
        <Form>
          <Row justify="start" align="middle" gutter={16}>
            <Col span={5}>
              <h4>{t("translation:report")}</h4>
              <Form.Item name="reportId">
                <Select
                  name="reportId"
                  size="large"
                  placeholder={t("translation:select_report")}
                >
                  {reports.map((c) => (
                    <Select.Option key={c.id} value={c.id}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={6}>
              <span className="blockText">{t("translation:row_count")}</span>
            </Col>
            <Col span={5}>
              <h4>{t("translation:comparison")}</h4>
              <Form.Item name="verb">
                <Select
                  name="verb"
                  size="large"
                  placeholder={t("translation:select_comparison")}
                >
                  {verbs.map((c) => (
                    <Select.Option key={c.value} value={c.value}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
              <h4>{t("translation:value")}</h4>
              <Form.Item name="value" hasFeedback={false}>
                <InputNumber name="value" min={0} max={1000000} size="large" />
              </Form.Item>
            </Col>
            <>
              <Col span={2}>
                <SubmitButton
                  type="primary"
                  size="large"
                  block
                  disabled={!dirty}
                >
                  {t("translation:save")}
                </SubmitButton>
              </Col>
              <Col span={3}>
                <NextButton
                  size="md"
                  variant="bordered"
                  color="default"
                  fullWidth
                  onClick={deleteWorkflowCriteriaHandler}
                  className="hover:border-blue-500 hover:text-blue-500 bg-white"
                >
                  {t("translation:remove")}
                </NextButton>
              </Col>
            </>
          </Row>
        </Form>
      ),
      [t, reports, verbs, deleteWorkflowCriteriaHandler],
    );

  return (
    <div>
      <Formik
        component={critieriaForm}
        enableReinitialize
        initialValues={{
          reportId: criteria.reportId,
          verb: criteria.verb,
          value: criteria.value,
        }}
        validationSchema={simpleSchemaBuilder([
          { name: "value", type: "number", required: true },
        ])}
        onSubmit={updateWorkflowCriteriaHandler}
      />{" "}
      <NextDivider>And</NextDivider>
    </div>
  );
}
