import React, { useState, useCallback } from "react";
import { Form, SubmitButton, Input } from "formik-antd";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col, Tabs } from "antd";
import { mssqlSchema, mssqlSchemaNoPwd } from "/app/src/schemas";
import { connectionService, servicesService } from "/app/src/services";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";
import { useTranslation } from "react-i18next";
import { testConnection } from "/app/src/helpers/connectionsStatus";
import { handleSubmissionErrors } from "/app/src/helpers/forms";
import { Connection as ConnectionType } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { ConnectionState, StatusType } from "/app/src/contexts/types";
import { useNavigate } from "react-router-dom";
import "./_notification.scss";
import NextButton from "/app/src/components/NextUi/Button";
import NotificationCard from "/app/src/components/NextUi/Notification";
import Logs from "/app/src/components/settings/connection/logs";
import Box from "/app/src/components/generic/components/box";

/**
 * Formats the form values to be sent to the API
 * @param values Values received from the form
 */
function formatForm(values: FormValues) {
  const formatted: {
    username: string | undefined;
    url: string | undefined;
    password?: string;
    token?: string;
  } = {
    username: values.username,
    url: values.url,
  };
  if (values.password) {
    formatted.password = values.password;
  }
  if (values.token) {
    formatted.token = values.token;
  }

  return formatted;
}

interface FormValues {
  url: string | undefined;
  token?: string;
  username: string | undefined;
  password?: string;
}

/**
 * Component to display the PowerPick MSSQL connection settings
 */
export default function Mssql() {
  const { t } = useTranslation();
  const [openNotification, setOpenNotification] = useState(false);

  const queryClient = useQueryClient();
  const [isTesting, setIsTesting] = useState(false);
  const { data: connection, isFetching } = useQuery({
    queryKey: ["connections", { type: "PowerPick SQL" }],
    queryFn: () => {
      return connectionService
        .getAll(buildParams({ type: "PowerPick SQL" }))
        .then((response) => {
          return { connection: response.connections[0] };
        });
    },
    initialData: { connection: {} as ConnectionType },
    select: (data) => data.connection,
  });

  const { mutateAsync: updateConnection } = useMutation({
    mutationFn: (connection: ConnectionType) => {
      return connectionService
        .updateSingle(connection.id, connection)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["connections", { type: "PowerPick SQL" }],
        response,
      );
      queryClient.setQueryData(
        ["powerpickConnection"],
        (old: ConnectionState) => {
          return {
            ...old,
            powerPick: response.connection.status ? 1 : 0,
          };
        },
      );
    },
  });

  const onFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    e.target.placeholder = "";
  }, []);

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (connection.tokenExists) {
        e.target.placeholder = "***********";
      }
    },
    [connection.tokenExists],
  );

  const testConnectionHandler = useCallback(
    (e) => {
      e.stopPropagation();
      testConnection(setIsTesting, connection, updateConnection);
    },
    [connection, updateConnection],
  );

  /**
   * Function to update the connection
   * @param connection connection to update
   */
  const connectionForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form layout="vertical">
          <Row justify="start" gutter={16} style={{ paddingBottom: "10px" }}>
            <Col span={20}>
              <h2>{t("translation:power_pick_mssql")}</h2>
            </Col>
            <Col
              span={1}
              style={{
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {connection.status === 0 ? (
                <IconBuilder icon="Warning" color="#e00000" size={32} />
              ) : (
                <IconBuilder icon="CircleCheck" color="#23ce6b" size={32} />
              )}
            </Col>
            <Col span={3}>
              <div style={{ float: "right" }}>
                <NextButton
                  size="sm"
                  variant="bordered"
                  color="default"
                  onClick={testConnectionHandler}
                  className="hover:border-blue-500 hover:text-blue-500"
                >
                  {isTesting
                    ? t("translation:testing___")
                    : t("translation:test_") + t("translation:connection")}
                </NextButton>
              </div>
            </Col>
          </Row>
          <Row justify="start" gutter={16}>
            <Col span={8}>
              <h3>{t("translation:power_pick_url")}</h3>
              <p>{t("translation:power_pick_url_description")}</p>
            </Col>
            <Col span={14} offset={2}>
              <Form.Item name="url" className="no-label">
                <Input
                  suffix
                  name="url"
                  className="token"
                  size="large"
                  placeholder="http://powerpick.ip.or.domain:1433"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="start" gutter={16}>
            <Col span={8}>
              <h3>{t("translation:power_pick_database_name")}</h3>
              <p>{t("translation:power_pick_database_descriptiom")}</p>
            </Col>
            <Col span={14} offset={2}>
              <Form.Item name="token" className="no-label">
                <Input.Password
                  name="token"
                  className={connection.tokenExists ? "password" : ""}
                  size="large"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  placeholder={
                    connection.tokenExists ? "***********" : "PowerPick"
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="start" gutter={16}>
            <Col span={8}>
              <h3>{t("translation:mssql_user_password")}</h3>
              <p>{t("translation:mssql_user_description")}</p>
            </Col>
            <Col span={6} offset={2}>
              <Form.Item name="username" className="no-label">
                <Input
                  suffix
                  name="username"
                  className="user"
                  size="large"
                  placeholder={t("translation:mssql_user")}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="password" className="no-label">
                <Input.Password
                  className={connection.passwordExists ? "password" : ""}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  placeholder={
                    connection.passwordExists
                      ? "***********"
                      : t("translation:mssql_password")
                  }
                  name="password"
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="start" gutter={16}>
            <Col span={4} offset={20}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || !isValid}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t, connection, isTesting, onFocus, onBlur, testConnectionHandler],
    );

  const navigate = useNavigate();

  const onClick = useCallback(() => {
    servicesService.restart().then(() => {
      queryClient.setQueryData(["status"], (oldData: StatusType) => {
        return {
          ...oldData,
          message: "restarting",
        };
      });
      navigate("/status/restart");
    });
  }, [queryClient, navigate]);

  const handleSubmit = useCallback(
    async (
      values: {
        username: string | undefined;
        url: string | undefined;
        password?: string;
        token?: string;
      },
      actions: FormikHelpers<FormValues>,
    ) => {
      return await updateConnection({
        id: connection.id,
        ...formatForm(values),
      })
        .then(() => {
          setOpenNotification(true);
        })
        .catch((errors) => {
          handleSubmissionErrors(errors, actions.setFieldError);
        });
    },
    [connection.id, updateConnection],
  );
  return (
    <Box>
      <NotificationCard
        headerText={t("translation:new_mssql_connection")}
        bodyText={t("translation:click_restart")}
        onClick={onClick}
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
      />
      <Tabs
        defaultActiveKey="1"
        tabPosition="left"
        items={[
          {
            label: <>{t("translation:settings")}</>,
            key: "1",
            children: !isFetching && (
              <Formik
                component={connectionForm}
                validationSchema={
                  connection.passwordExists ? mssqlSchemaNoPwd : mssqlSchema
                }
                initialValues={{
                  url: connection.url,
                  username: connection.username,
                  password: "",
                  token: "",
                }}
                enableReinitialize
                onSubmit={handleSubmit}
              />
            ),
          },
          {
            label: t("translation:logs"),
            key: "2",
            children: <Logs isPowerPickConnection connection={connection} />,
          },
        ]}
      />
    </Box>
  );
}
