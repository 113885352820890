import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { roleService } from "/app/src/services";
import { Row, Col, message } from "antd";
import { Formik, FormikProps } from "formik";
import { Form, SubmitButton, Input, Select } from "formik-antd";
import { userProfileSchema } from "/app/src/schemas";
import {
  useAuthState,
  updateUserContext,
  useAuthDispatch,
  logout,
} from "/app/src/contexts/authentication";
import { getTimeZones } from "/app/src/helpers/time";
import { SearchFilter } from "/app/src/components/generic/components/searchFilter";
import { handleSubmissionErrors } from "/app/src/helpers/forms";
import { User, Role } from "/app/src/models";
import { mapTimeZones } from "/app/src/components/generic/formatting/timeZone";
import { useTimezoneContext } from "/app/src/contexts/hooks/useTimezoneContext";
import NextModal from "/app/src/components/NextUi/Modal";
import NextButton from "/app/src/components/NextUi/Button";
import ModalBuilder from "/app/src/components/NextUi/helpers/ModalBuilder";
import Box from "/app/src/components/generic/components/box";
import NewPassword from "./newPassword";

type FormValues = {
  email: string | undefined;
  username: string | undefined;
  roleId: number | undefined;
  language: string | undefined;
  type: string | undefined;
  currentPassword: string;
  password: string;
  timeZone: string | undefined;
};

function formatForm(values: FormValues, currentUser: User) {
  const submittedValues: {
    password: string;
    currentPassword: string;
    language: string;
    timeZone: string;
    type: string;
    roleId: number;
    email?: string;
    username?: string;
  } = {
    password: values.password,
    currentPassword: values.currentPassword,
    language: values.language,
    timeZone: values.timeZone,
    type: values.type,
    roleId: values.roleId,
  };
  if (currentUser.email !== values.email) {
    submittedValues.email = values.email;
  }
  if (currentUser.username !== values.username) {
    submittedValues.username = values.username;
  }

  return submittedValues;
}

export default function Profile({
  profileUser,
  updateUser,
}: {
  profileUser: User;
  updateUser: (user: User) => Promise<any>;
}) {
  const { user } = useAuthState();
  const dispatch = useAuthDispatch();
  const { globalTimeZone } = useTimezoneContext();
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const languages = useMemo(
    () => [
      { code: "en", name: "English" },
      { code: "fr", name: "Français" },
    ],
    [],
  );

  const changeLanguage = useCallback(
    (lang: string) => {
      i18n.changeLanguage(lang);
    },
    [i18n],
  );
  const success = useCallback(() => {
    message.success(t("translation:ok"));
  }, [t]);
  const relogin = useCallback(() => {
    message.success(t("translation:relogin"));
  }, [t]);

  //Get roles
  const { data: roles } = useQuery({
    queryKey: ["roles"],
    queryFn: () => {
      return roleService.getAll();
    },
    initialData: { roles: [] },
    select: (data: { roles: Role[] }) => {
      return data.roles;
    },
  });

  const onFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    e.target.placeholder = "";
  }, []);

  const onBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    e.target.placeholder = "***********";
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const updateProfileForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ errors, touched, isValid, dirty }) => {
        return (
          <Form layout="vertical">
            <Row justify="start" gutter={20}>
              <Col span={12}>
                <Form.Item name="username" label={t("translation:username")}>
                  <Input
                    suffix
                    name="username"
                    placeholder={t("translation:enter_username")}
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="email" label={t("translation:email_address")}>
                  <Input suffix name="email" size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="start" gutter={20}>
              <Col span={12}>
                <Form.Item name="roleId" label={t("translation:role")}>
                  <Select
                    name="roleId"
                    size="large"
                    placeholder={t("translation:select_role")}
                  >
                    {roles.map((c: Role) => (
                      <Select.Option key={c.id} value={c.id}>
                        {c.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="language" label={t("translation:language")}>
                  <Select
                    name="language"
                    size="large"
                    placeholder={t("translation:select_language")}
                  >
                    {languages.map((c) => (
                      <Select.Option key={c.code} value={c.code}>
                        {c.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {user?.isAdmin && (
              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item name="type" label={t("translation:type")}>
                    <Select
                      name="type"
                      size="large"
                      placeholder={t("translation:select_type")}
                      disabled={profileUser?.type === "hidden"}
                    >
                      <Select.Option key={1} value={"user"}>
                        {t("translation:user")}
                      </Select.Option>
                      <Select.Option key={1} value={"application"}>
                        {t("translation:application")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item
                  name="currentPassword"
                  label={t("translation:current_password")}
                >
                  <Input.Password
                    name="currentPassword"
                    onFocus={onFocus}
                    onBlur={onBlur}
                    placeholder={"***********"}
                    size="large"
                    className={
                      errors.currentPassword && touched.password
                        ? "input-error"
                        : "password"
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="password"
                  label={t("translation:new_password")}
                >
                  <NewPassword />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="timeZone" label={t("translation:Time_Zone")}>
                  <SearchFilter
                    list={getTimeZones()}
                    name="timeZone"
                    placeholder={globalTimeZone}
                    sort
                    mapOptionsFn={mapTimeZones}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="type" label={t("translation:type")}>
                  <Input
                    name="type"
                    placeholder={t("translation:type")}
                    size="large"
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <SubmitButton
                  type="primary"
                  size="large"
                  disabled={!(dirty && isValid)}
                >
                  {t("translation:save")}
                </SubmitButton>
                <NextModal
                  disableAnimation={false}
                  isOpen={isModalOpen}
                  onOpenChange={setIsModalOpen}
                  placement="top"
                >
                  {ModalBuilder({
                    warning: true,
                    modalHeader: t("translation:activate_user_warning"),
                    modalFooter: (
                      <NextButton
                        fullWidth
                        onClick={closeModal}
                        color="primary"
                      >
                        {t("translation:ok")}
                      </NextButton>
                    ),
                  })}
                </NextModal>
              </Col>
            </Row>
          </Form>
        );
      },
      [
        t,
        roles,
        languages,
        user?.isAdmin,
        profileUser?.type,
        onFocus,
        onBlur,
        globalTimeZone,
        isModalOpen,
        closeModal,
      ],
    );

  const onSubmit = useCallback(
    (values, { setSubmitting, setFieldError, resetForm }) => {
      if (profileUser.status === 2) {
        // show pop up
        setIsModalOpen(true);
        setSubmitting(false);
      } else {
        if (!profileUser?.id) {
          return;
        }
        updateUser(formatForm(values, profileUser))
          .then((response) => {
            //update language and timeZone settings if user profile matches logged in user
            if (response?.user.id === user.id) {
              //only update language if different
              if (values.language !== profileUser.language && values.language) {
                changeLanguage(values.language);
              }
              updateUserContext(dispatch, response.user);
              if (response.user.username !== user.username) {
                logout(dispatch);
                relogin();
                return;
              }
            }
            success();
            resetForm();
            setSubmitting(false);
          })
          .catch((error) => {
            handleSubmissionErrors(error.errors, setFieldError);
            setSubmitting(false);
          });
      }
    },
    [
      profileUser,
      updateUser,
      user.id,
      user.username,
      success,
      dispatch,
      changeLanguage,
      relogin,
    ],
  );

  return (
    <Box>
      <Formik
        enableReinitialize
        component={updateProfileForm}
        initialValues={{
          username: profileUser.username,
          email: profileUser.email,
          password: "",
          currentPassword: "",
          roleId: profileUser.roleId,
          language: profileUser.language,
          type: profileUser.type,
          timeZone: profileUser.timeZone,
        }}
        validationSchema={userProfileSchema}
        onSubmit={onSubmit}
      />
    </Box>
  );
}
