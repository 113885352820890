// @ts-nocheck - ignoring typescript errors as Select from formik-antd is not properly typed
// ignoring typescript errors as Select from formik-antd is not properly typed
// the antd version they use is a few versions behind. Specific errors have to do
// with filterOption and filterSort params of Select

import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { roleService, userService } from "/app/src/services";
import { Row, Col, message } from "antd";
import { Formik, FormikProps } from "formik";
import { Form, SubmitButton, Input, Select } from "formik-antd";
import { newUserSchema } from "/app/src/schemas";
import { useTranslation } from "react-i18next";
import { getTimeZones } from "/app/src/helpers/time";
import { handleSubmissionErrors } from "/app/src/helpers/forms";
import { Role as RoleType } from "/app/src/models";
import { useTimezoneContext } from "/app/src/contexts/hooks/useTimezoneContext";
import Box from "/app/src/components/generic/components/box";

interface FormValues {
  username: string;
  email: string;
  password: string;
  role: number;
  timeZone: string;
}

/**
 *
 * @param values Form values
 * @returns Formatted form values
 */
function formatForm(values: FormValues) {
  return {
    username: values.username,
    email: values.email,
    password: values.password,
    roleId: values.role,
    timeZone: values.timeZone,
  };
}

export default function NewUser() {
  const { t } = useTranslation();
  const { globalTimeZone } = useTimezoneContext();
  const navigate = useNavigate();
  const [roles, setRoles] = useState<RoleType[]>([]);

  useEffect(() => {
    roleService.getAll().then((response) => setRoles(response.roles));
  }, []);

  const success = useCallback(() => {
    message.success(t("translation:user_saved_sucessfully"));
  }, [t]);

  const sortTimeZones = useCallback((optionA, optionB) => {
    optionA.children
      .toLowerCase()
      .localeCompare(optionB.children.toLowerCase());
  }, []);

  const filterTimeZones = useCallback((input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }, []);

  const newUserForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ errors, touched, isValid, dirty }) => (
        <Form layout="vertical">
          <Row justify="start" gutter={20}>
            <Col span={12}>
              <Form.Item name="username" label={t("translation:username")}>
                <Input
                  suffix
                  name="username"
                  placeholder={t("translation:enter_username")}
                  size="large"
                  className={
                    errors.password && touched.password
                      ? "input-error"
                      : undefined
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="email"
                label={t("translation:email")}
                className={
                  errors.email && touched.email ? "input-error" : undefined
                }
              >
                <Input suffix name="email" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item name="password" label={t("translation:new_password")}>
                <Input.Password
                  name="password"
                  size="large"
                  className={
                    errors.password && touched.password
                      ? "input-error"
                      : undefined
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="role" label={t("translation:role")}>
                <Select
                  name="role"
                  size="large"
                  placeholder={t("translation:select_role")}
                >
                  {roles.map((c) => (
                    <Select.Option key={c.id} value={c.id}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="timeZone" label={t("translation:Time_Zone")}>
                <Select
                  name="timeZone"
                  size="large"
                  placeholder={globalTimeZone}
                  showSearch
                  style={{ width: 200 }}
                  optionFilterProp="children"
                  filterOption={filterTimeZones}
                  filterSort={sortTimeZones}
                >
                  {getTimeZones().map((z) => (
                    <Select.Option key={z.id} value={z.name}>
                      {z.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <SubmitButton
                type="primary"
                size="large"
                disabled={!(dirty && isValid)}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t, roles, globalTimeZone, filterTimeZones, sortTimeZones],
    );

  const createNewUser = useCallback(
    (values, actions) => {
      userService.createSingle(formatForm(values)).then((response) => {
        if (response?.errors) {
          handleSubmissionErrors(response.errors, actions.setFieldError);
        } else if (response.user) {
          success();
          navigate(`/users/${response.user.id}`);
        }
      });
    },
    [navigate, success],
  );

  return (
    <Box>
      <Formik
        component={newUserForm}
        validationSchema={newUserSchema}
        initialValues={{
          username: "",
          email: "",
          password: "",
          role: undefined,
          timeZone: "",
        }}
        onSubmit={createNewUser}
      />
    </Box>
  );
}
