import React, { useCallback, useState } from "react";
import ConvertToLocalTimezone, {
  PowerPickTimeZoneToLocal,
} from "/app/src/components/generic/formatting/dateTime";
import _ from "lodash";

import { reportRowService, reportService } from "/app/src/services";
import { buildParams } from "/app/src/helpers/params";
import { useTranslation } from "react-i18next";
import { Report, ReportColumn } from "/app/src/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { ColumnSort, createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";

// Check for different formatting depending on data type
const Cell = ({
  value,
  type,
  baseTable,
}: {
  value: string;
  type: string;
  baseTable: string;
}) => {
  if (type === "DateTime") {
    if (["APICall", "Log", "Task"].includes(baseTable)) {
      return <ConvertToLocalTimezone date={value} />;
    }
    return <PowerPickTimeZoneToLocal date={value} />;
  } else {
    return <div>{value}</div>;
  }
};

export default function ReportTable({
  report,
  columns,
  dateValues: dataValues,
}: {
  report: Report;
  columns: ReportColumn[];
  dateValues: (values: {
    cache_expiration: string;
    powerpick_timestamp: string;
    variance_timestamp: string;
  }) => void;
}) {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const columnHelper = createColumnHelper<ReportColumn>();
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([
    { id: String(report.sortColumnId), desc: report.sortDescending },
  ]);
  const queryClient = useQueryClient();

  function formatSortData(sortData: ColumnSort[]) {
    if (sortData.length) {
      return {
        sortDirection: sortData[0].desc,
        sortColumnId: sortData[0].id,
      };
    } else {
      return {
        sortColumnId: null,
      };
    }
  }
  const reportRowsQuery = useQuery({
    queryKey: ["reportRows", report.id, page, pageSize],
    queryFn: () =>
      reportRowService
        .getAll(report.id, buildParams({ limit: pageSize, page }))
        .then((data) => {
          dataValues({
            cache_expiration: data.cache_expiration,
            powerpick_timestamp: data.powerpick_timestamp,
            variance_timestamp: data.variance_timestamp,
          });
          return data;
        }),
    enabled: columns.length > 0,
    initialData: { report_rows: [] },
    keepPreviousData: true,
  });
  const reportRowsCountQuery = useQuery({
    queryKey: ["reportRowsCount", report.id],
    queryFn: () =>
      reportRowService.getAll(report.id, buildParams({ countOnly: true })),
    enabled: columns.length > 0,
    initialData: { count: 0 },
    select: (data) => data.count,
  });
  const { mutateAsync: updateReport } = useMutation({
    mutationFn: (data: {
      [key: string]: string | number | boolean | Date | undefined;
    }) => reportService.updateSingle(report.id, data).then(handlePromiseError),
    onSuccess: (data) => {
      queryClient.setQueryData(["report", report.id], data);
      reportRowsQuery.refetch();
    },
  });

  const updateSort = useCallback(
    (updater) => {
      setSort((oldSort) => {
        const newSortValue =
          updater instanceof Function ? updater(oldSort) : updater;
        updateReport(formatSortData(newSortValue));
        return newSortValue;
      });
    },
    [setSort, updateReport],
  );

  const formatColumns = useCallback(
    (columns: ReportColumn[]) => {
      // Create React Table column data
      columns.sort((a, b) => {
        if (a?.position && b?.position) {
          return Number(a.position) > Number(b.position) ? 1 : -1;
        } else {
          return 0;
        }
      });

      const formatted = _.chain(columns)
        .map((column) => {
          return columnHelper.accessor(column.id.toString(), {
            id: column.id.toString(),
            cell: (info) => {
              if (
                column.type === "comparison" &&
                column.filterType === "DateTime"
              ) {
                return (
                  <Cell
                    value={info.getValue() as string}
                    type="Number"
                    baseTable={column.baseTable}
                  />
                );
              }
              return (
                <Cell
                  value={info.getValue() as string}
                  type={column.filterType}
                  baseTable={column.baseTable}
                />
              );
            },
            header: column.name,
            meta: {
              clickable: false,
            },
          });
        })
        .value();
      return formatted;
    },
    [columnHelper],
  );

  return (
    <Table
      rows={reportRowsQuery.data.report_rows}
      tableColumns={formatColumns(columns)}
      length={reportRowsCountQuery.data}
      sort={sort}
      setSort={updateSort}
      paginationEnabled={{
        currentPage: page,
        pageSize,
        setPage,
        setPageSize,
      }}
      enableRowSelection
      emptyText={t("translation:no_rows")}
      loading={reportRowsQuery.isFetching || reportRowsCountQuery.isFetching}
    />
  );
}
