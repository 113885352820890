import React, { useEffect, useState, useRef, useCallback } from "react";
import { Row, Col, InputNumber } from "antd";
import { UndoOutlined } from "@ant-design/icons";
import MaterialForm from "./materialForm";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { isEnterKeyOrClick } from "/app/src/helpers/onClickOnKeyPress";
import NextButton from "/app/src/components/NextUi/Button";

interface FormValues {
  property: string;
  bin: string;
  maxStock: string;
}
export default function Material({
  material,
  quantity,
  changeMaterial,
  index,
  activeMaterial,
}: {
  material: string;
  quantity: number;
  changeMaterial: (index: number) => void;
  index: number;
  activeMaterial: number;
}) {
  const { t } = useTranslation();
  const [currentGrouped, setCurrentGrouped] = useState(0);
  const [skipped, setSkipped] = useState(false);
  const [visible, setVisible] = useState(true);
  const [activeKey, setActiveKey] = useState(0);
  const [count, setCount] = useState(0);
  const materialFormRef = useRef<FormikProps<FormValues>>(null);

  useEffect(() => {
    if (index === currentGrouped) {
      setActiveKey(1);
      // setSkipped(false)
    } else {
      setActiveKey(0);
    }
  }, [currentGrouped, index]);

  useEffect(() => {
    setCount(quantity);
  }, [quantity]);

  useEffect(() => {
    if (index === activeMaterial) {
      setCurrentGrouped(0);
    } else {
      setCurrentGrouped(-1);
    }
  }, [index, activeMaterial]);

  const setGrouping = useCallback(
    (i: number) => {
      changeMaterial(index);
      setCurrentGrouped(i);
    },
    [changeMaterial, index],
  );
  const confirm = useCallback(() => {
    //process orderlines here,
    //if no errors then go next grouped
    setVisible(false);
    setGrouping(index + 1);
  }, [index, setGrouping]);
  const handleSubmit = useCallback(() => {
    if (materialFormRef.current) {
      materialFormRef.current.resetForm();
    }
  }, []);
  const skipGrouping = useCallback(() => {
    setSkipped(true);
    setGrouping(index + 1);
  }, [index, setGrouping]);
  const undoSkipGrouping = useCallback(() => {
    setSkipped(false);
    setGrouping(index);
  }, [index, setGrouping]);

  const handleEnterPressOrClick = useCallback((event) => {
    if (isEnterKeyOrClick(event)) {
      event.stopPropagation();
    }
  }, []);

  const confirmMaterial = useCallback(() => {
    confirm();
    handleSubmit();
  }, [confirm, handleSubmit]);

  const setMaterialCount = useCallback((e) => {
    if (e) {
      setCount(e);
    }
  }, []);

  return (
    <Col span={24}>
      {visible ? (
        <div
          className={activeKey === 1 ? "serialNumbersSelected" : "materialName"}
        >
          <Row align="middle">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 8 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <p className="materialText">{material}</p>
              <p style={{ marginBottom: "0", fontSize: "10px" }}>
                {t("translation:original_quantity")}: {quantity}
              </p>
            </Col>

            {skipped && index !== currentGrouped ? (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 16 }}
                md={{ span: 16 }}
                lg={{ span: 16 }}
              >
                <Row align="middle">
                  <Col span={20}>
                    <p style={{ float: "right", marginBottom: "0" }}>
                      {t("translation:skipped")}
                    </p>
                  </Col>
                  <Col span={4}>
                    <NextButton
                      className="float-right hover:text-blue-500 hover:border-blue-500 min-w-4"
                      onClick={undoSkipGrouping}
                      variant="bordered"
                      size="sm"
                    >
                      <UndoOutlined />
                    </NextButton>
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 16 }}
                md={{ span: 16 }}
                lg={{ span: 16 }}
              >
                <div
                  role="button"
                  tabIndex={0}
                  onClick={handleEnterPressOrClick}
                  onKeyDown={handleEnterPressOrClick}
                  style={{ float: "right" }}
                >
                  {activeKey === 1 && (
                    <div>
                      <Row
                        gutter={20}
                        align="middle"
                        style={{ paddingTop: "10px", paddingBottom: "10px" }}
                      >
                        <Col>
                          <InputNumber
                            min={0}
                            defaultValue={quantity}
                            onChange={setMaterialCount}
                          />
                        </Col>
                        <Col>
                          <NextButton
                            onClick={skipGrouping}
                            color="default"
                            size="sm"
                          >
                            {t("translation:skip")}
                          </NextButton>
                        </Col>
                        <Col>
                          <NextButton
                            onClick={confirmMaterial}
                            color="primary"
                            size="sm"
                          >
                            {t("translation:confirm")}
                          </NextButton>
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
              </Col>
            )}
          </Row>
          {!skipped && (
            <Row gutter={20} style={{ paddingTop: "10px" }}>
              <Col span={24}>
                <MaterialForm formRef={materialFormRef} />
              </Col>
            </Row>
          )}
        </div>
      ) : (
        <Row className="materialName" align="middle">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 8 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <p className="materialText">{material}</p>
            <p style={{ marginBottom: "0", fontSize: "10px" }}>
              {t("translation:original_quantity")}: {quantity}
            </p>
          </Col>
          <Col span={16}>
            <p style={{ marginBottom: "0", fontSize: "10px", float: "right" }}>
              {count}
            </p>
          </Col>
        </Row>
      )}
    </Col>
  );
}
