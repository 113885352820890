import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Descriptions from "/app/src/components/generic/components/descriptions";
import DynamicFields from "/app/src/components/explorer/generic/dynamicFields";

import { orderService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Order as OrderType } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
import Box from "/app/src/components/generic/components/box";
import { useHandleNavigationError } from "/app/src/helpers/api";

export default function Order() {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const handleNavigationError = useHandleNavigationError();

  //Get the Single Order from URL
  const { data: order } = useQuery({
    queryKey: ["order", orderId],
    queryFn: () => {
      return orderService.getSingle(orderId).catch(() => {
        handleNavigationError();
      });
    },
    initialData: { order: {} },
    retry: false,
    select: (data: { order: OrderType }) => {
      return data.order;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{order?.name}</title>
      </Helmet>
      <Box>
        <h1>{order?.name}</h1>
        <h4>{t("translation:information")}</h4>
        <Descriptions
          items={[
            {
              label: t("translation:creation_date"),
              value: order?.creationDate,
            },
            {
              label: t("translation:status"),
              value: order?.status,
            },
            {
              label: t("translation:owner"),
              value: order?.owner,
            },
            {
              label: t("translation:priority"),
              value: order?.priority,
            },
            {
              label: t("translation:lines_number"),
              value: order?.order_lines?.length,
            },
          ]}
          bordered
        />
        <h4>{t("translation:information_fields")}</h4>
        <Descriptions
          items={[
            {
              label: t("translation:info1"),
              value: order?.Info1,
            },
            {
              label: t("translation:info2"),
              value: order?.Info2,
            },
            {
              label: t("translation:info3"),
              value: order?.Info3,
            },
            {
              label: t("translation:info4"),
              value: order?.Info4,
            },
            {
              label: t("translation:info5"),
              value: order?.Info5,
            },
          ]}
          bordered
        />
        <DynamicFields object={order} baseTable="Masterorder" />
      </Box>
    </div>
  );
}
